<template>
  <div v-if="isMobile" class="lesson_control_container mobile">
    <div class="lesson_control_mobile">
      <LessonDownloads v-if="getCurrentLesson.attachments && downloadStyleFile === 'menu'" />
      <div @click="sendToPrevLesson" class="btn-player-mobile" style="padding-top: 13px;">
        <svg class="svg_icon_color" width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1L1 9L9 17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <span style="margin-top: 26px;" class="font-btn-player-mobile">{{ $t("player.previous") }}</span>
      </div>
      <div @click="markLesson(getCurrentLesson)" v-if="getCurrentLesson.is_completed" class="concluded_lesson btn-player-mobile" style="padding-top: 13px;">
        <svg class="svg_icon_color" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3435 8.63665C18.5442 8.82635 18.553 9.14281 18.3633 9.34348L10.8009 17.3435C10.7064 17.4434 10.575 17.5 10.4375 17.5C10.3 17.5 10.1686 17.4434 10.0742 17.3435L6.63665 13.7071C6.44695 13.5064 6.45585 13.19 6.65652 13.0003C6.85719 12.8106 7.17365 12.8195 7.36335 13.0202L10.4375 16.2722L17.6367 8.65652C17.8264 8.45585 18.1428 8.44695 18.3435 8.63665Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"/>
        </svg>
        <span style="margin-top: 26px;" class="font-btn-player-mobile">{{ $t("player.concluded") }}</span>
      </div>
      <div @click="markLesson(getCurrentLesson)" v-if="!getCurrentLesson.is_completed && liberatedToClickNext()" class="btn-player-mobile" style="padding-top: 13px;">
        <svg class="svg_icon_color_fill" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3435 8.63665C18.5442 8.82635 18.553 9.14281 18.3633 9.34348L10.8009 17.3435C10.7064 17.4434 10.575 17.5 10.4375 17.5C10.3 17.5 10.1686 17.4434 10.0742 17.3435L6.63665 13.7071C6.44695 13.5064 6.45585 13.19 6.65652 13.0003C6.85719 12.8106 7.17365 12.8195 7.36335 13.0202L10.4375 16.2722L17.6367 8.65652C17.8264 8.45585 18.1428 8.44695 18.3435 8.63665Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"/>
        </svg>
        <span style="margin-top: 26px;" class="font-btn-player-mobile">{{ $t("player.conclude") }}</span>
      </div>
      <div @click="sendToNextLesson" v-if="(getHasNextLesson || getHasNextModule) && liberatedToClickNext()" class="btn-player-mobile" style="padding-top: 13px;">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 15V1M1 15L10 8L1 1V15Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span style="margin-top: 26px;" class="font-btn-player-mobile">{{ $t("player.next") }}</span>
      </div>
    </div>  
  </div>
  <div v-else class="lesson_control_container nowrap">
    <div class="nowrap">
<!--      <div class="lesson_control_btn">-->
<!--        <svg class="svg_icon_color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M5.06449 8.19995L8.31609 1C8.96287 1 9.58316 1.25285 10.0405 1.70294C10.4979 2.15302 10.7548 2.76347 10.7548 3.39998V6.59996H15.3558C15.5915 6.59734 15.8249 6.64517 16.0399 6.74014C16.2549 6.83511 16.4464 6.97495 16.601 7.14997C16.7557 7.325 16.8698 7.53102 16.9355 7.75376C17.0012 7.9765 17.017 8.21064 16.9816 8.43995L15.8598 15.6399C15.801 16.0214 15.6041 16.3692 15.3053 16.6191C15.0066 16.869 14.6261 17.0043 14.234 16.9999H5.06449M5.06449 8.19995V16.9999M5.06449 8.19995H2.6258C2.19461 8.19995 1.78108 8.36852 1.47618 8.66858C1.17129 8.96864 1 9.3756 1 9.79994V15.3999C1 15.8243 1.17129 16.2312 1.47618 16.5313C1.78108 16.8313 2.19461 16.9999 2.6258 16.9999H5.06449" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div class="lesson_control_btn">-->
<!--        <svg class="svg_icon_color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M12.9355 9.80005L9.68391 17C9.03713 17 8.41684 16.7471 7.95949 16.2971C7.50215 15.847 7.24521 15.2365 7.24521 14.6V11.4L2.64421 11.4C2.40854 11.4027 2.17511 11.3548 1.9601 11.2599C1.74508 11.1649 1.55361 11.0251 1.39896 10.85C1.24431 10.675 1.13018 10.469 1.06447 10.2462C0.998759 10.0235 0.983043 9.78936 1.01841 9.56005L2.14021 2.36009C2.199 1.97857 2.39592 1.63081 2.69468 1.3809C2.99345 1.13098 3.37392 0.99574 3.76601 1.0001L12.9355 1.0001M12.9355 9.80005L12.9355 1.0001M12.9355 9.80005H15.3742C15.8054 9.80005 16.2189 9.63148 16.5238 9.33142C16.8287 9.03136 17 8.6244 17 8.20006V2.60009C17 2.17575 16.8287 1.76879 16.5238 1.46873C16.2189 1.16867 15.8054 1.0001 15.3742 1.0001H12.9355" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        </svg>-->
<!--      </div>-->
      <span style="text-align: center;" @click="markLesson(getCurrentLesson)" v-if="getCurrentLesson.is_completed" class="concluded_lesson lesson_control_mark_as_concluded">
        {{ $t("player.concluded") }}
        <svg class="ml-2 svg_icon_color_concluded" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L4.75 9L1 5.36364" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
      <span @click="markLesson(getCurrentLesson)" v-if="!getCurrentLesson.is_completed && getCurrentLesson.is_liberated && liberatedToClickNext()" class="lesson_control_mark_as_concluded">
        <span style="text-align: center;">
          {{ $t("player.mark_completed") }}
        </span>
        <svg class="ml-2 svg_icon_color" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L4.75 9L1 5.36364" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    </div>
    
    <div class="nowrap pl-2">
      <span @click="sendToPrevLesson" v-if="getHasPrevLesson || getHasPrevModule" class="lesson_control_next_btn nowrap">
        <svg class="svg_icon_color mr-2" width="7" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 1L1 7L7 13" />
          <path d="M7 1L1 7L7 13" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span v-if="getPlayerStatus === 'loading-prev-lesson'" style="min-width: 80px;" class="skeleton"></span>
        <span v-else>
          {{ $t("player.previous") }}
        </span>
      </span>
  
      <span data-anima="left" @click="sendToNextLesson" v-if="(getHasNextLesson || getHasNextModule) && liberatedToClickNext()" class="ml-3 lesson_control_next_btn">
        <span v-if="getPlayerStatus === 'loading-next-lesson'" style="min-width: 80px;" class="skeleton"></span>
        <span class="nowrap" style="align-items: center;" v-else>
          {{ $t("player.next") }}
          <svg class="ml-2 svg_icon_color" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </span>
      <span data-anima="right" @click="() => {}" v-if="(getHasNextLesson || getHasNextModule) && !liberatedToClickNext()" class="ml-3 lesson_control_next_btn next_in">
        <span v-if="getPlayerStatus === 'loading-next-lesson'" style="min-width: 80px;" class="skeleton"></span>
        <span class="nowrap fontColorPercentage" style="align-items: center;" v-else>          
          {{ $t("player.next_in") }}
          <span style="width: 30px; padding-left: 6px;">
            {{ restantPercentage() }}%
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import LessonDownloads from '../lessonDownloads/index.vue'

export default {
  props: ['isMobile'],
  computed: {
    ...mapGetters([
      'getPlayerStatus',
      'getCurrentLesson', 
      'getHasNextLesson', 
      'getHasPrevLesson', 
      'getIsMobile', 
      'getHasNextModule', 
      'getHasPrevModule',
      'getPlayerConfig',
    ]),
    downloadStyleFile() { 
      return this.$store.state.metas.metas.download_style_file || "menu"
    }
  },
  methods: {
    ...mapActions([
      "sendToNextLesson", 
      "sendToPrevLesson", 
      "markLesson", 
      "fetchMetas"
    ]),
    restantPercentage() {
      const actualPercentage = this.getCurrentLesson.percent || 0;
      const target = this.getPlayerConfig.minimumPercentageToCompleteLesson - parseInt(actualPercentage);
      return target;
    },
    liberatedToClickNext() {
      const isApplicable = ['youtube', 'vimeo', 'panda'].includes(this.getCurrentLesson.mediaType);
      if (!isApplicable || !this.getPlayerConfig.limitMinimumPercentageLessonIsActive) return true;
      if (this.getCurrentLesson.is_completed) return true;

      const percent = this.getCurrentLesson.percent || 0;
      let targetPercent = this.getPlayerConfig.minimumPercentageToCompleteLesson == 100 ? 99 : this.getPlayerConfig.minimumPercentageToCompleteLesson;
      return percent >= targetPercent;
    }
  },
  components: {
    LessonDownloads
  },
  async created() {
    await this.fetchMetas(["download_style_file"]);
  }
}
</script>

<style lang="scss">
  .next_in {
    color: var(--bordercolor-v2) !important;
    cursor: not-allowed !important;
    background: var(--background-v2) !important;
    border: 1px solid var(--bordercolor-v2) !important;
  }
  .font-btn-player-mobile {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 6px;
    color: var(--fontcolor-v2);
  }
  .lesson_control_mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .btn-player-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .lesson_control_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .mobile {
    margin-top: 0px;
  }
  .lesson_control_mark_as_concluded {
    cursor: pointer;
    height: 50px;
    padding-left: 28px;
    padding-right: 28px;
    background: var(--background2-v2);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--fontcolor-v2);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lesson_control_next_btn {
    cursor: pointer;
    height: 50px;
    padding-left: 28px;
    padding-right: 28px;
    background: var(--background2-v2);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--fontcolor-v2);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lesson_control_next_btn:hover {
    background: var(--fundodowload);
    transition: background 0.2s ease-in-out;
  }
  .lesson_control_btn {
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background2-v2);
    border-radius: 50%;
    margin-right: 15px;
  }
  .lesson_control_btn:hover {
    background: var(--fundodowload);
    transition: background 0.2s ease-in-out;
  }
  .svg_icon_color {
    stroke: var(--fontcolor-v2) !important;
  }
  .svg_icon_color_fill {
    stroke: var(--fontcolor-v2);
    fill: var(--fontcolor-v2);
  }
  .svg_icon_color_only_fill {
    stroke: var(--fontcolor-v2);
  }
  .svg_icon_color_concluded {
    stroke: var(--maincolor);
  }
  .nowrap {
    display: flex;
    flex-wrap: nowrap;
  }
  .lesson_control_mark_as_concluded:hover {
    background: var(--fundodowload);
    transition: background 0.2s ease-in-out;
  }
  .skeleton {
    animation: skeleton-loading 0.6s linear infinite alternate;
    height: 12px; 
    width: 100%;
  }

  @keyframes skeleton-loading {
    0% {
      opacity: 0.5;
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
      opacity: 0.8;
    }
  }
  .btn-player-mobile svg {
    stroke: var(--fontcolor-v2);
  }
  .concluded_lesson {
    // color: var(--maincolor) ;
    // width: 25px;
    stroke: var(--maincolor) !important;
  }
.fontColorPercentage {
  color: var(--maincolor);
  opacity: 0.6;
}
</style>